<template>
    <Main>
        <div class="deep-products" id="product-page">
            <OurProducts />
            <div class="flex justify-center container-button show">
                <Button1 @onClick="$router.go(-1)">Back</Button1>
            </div>
        </div>
    </Main>
</template>

<script>
import Main from '@/components/Main';
import Button1 from '@/components/Button1';
import ContactUs from '../home/ContactUs';
import OurProducts from '../home/OurProducts';

export default {
    components: {
        Main,
        Button1,
        ContactUs,
        OurProducts,
    },

    mounted() {
        this.$nextTick(() => {
            const element = document.getElementById('product-page');
            element.scrollIntoView();
        });
    },
};
</script>
<style scoped lang="scss">
.deep-products {
    padding: 80px 0;
    background: #f8f8f8;
    overflow: hidden;
    :deep(.arrow a) {
        display: none;
    }

    :deep(.products) {
        flex-wrap: wrap !important;
        .product {
            display: block !important;
        }
    }

    :deep(.separator) {
        display: none;
    }

    :deep(.lib-paddings) {
        padding: 0;
    }

    :deep(.products-wrapper) {
        overflow: visible;
    }

    :deep(aside) {
        .brands {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 1300px;
            padding-right: 20px;
            padding-left: 20px;
            .brand {
                -webkit-animation: none !important;
                -moz-animation: none !important;
                -o-animation: none !important;
                animation: none !important;
            }
        }
        .hide-on-showmore {
            display: none;
        }
        .show-on-showmore {
            display: flex;
        }
    }
    :deep(.arrow-container) {
        display: none !important;
    }
    :deep(.container-button:not(.show)) {
        display: none !important;
    }
    .container-button {
        padding-top: 41.6px !important;
    }
}
</style>
